import {
  LoaderFunction,
  MetaArgs,
  MetaDescriptor,
  MetaFunction,
} from "@remix-run/cloudflare";

import { PRODUCTION_DOMAIN } from "constants/domains";

interface MetaConstructorParams {
  isRoot?: boolean;
  isCanonical?: boolean;
  title?: string;
  description?: string;
  ogImage?: string;
  robots?: string[];
}

interface GetMeta<
  Loader extends LoaderFunction | unknown = unknown,
  ParentsLoaders extends Record<string, LoaderFunction | unknown> = Record<
    string,
    unknown
  >,
> {
  (
    params: MetaConstructorParams,
    args: MetaArgs<Loader, ParentsLoaders>,
  ): MetaDescriptor[];
}

const getMeta: GetMeta = (
  {
    title,
    description,
    ogImage,
    isCanonical,
    isRoot,
    robots = ["index", "follow"],
  },
  { location },
) => {
  const currentTitle = isRoot ? title : `${title} | Coindisco`;

  const meta: MetaDescriptor[] = [
    { title: currentTitle },
    {
      name: "robots",
      content: robots.join(","),
    },
    { name: "apple-mobile-web-app-capable", content: "yes" },
    { name: "apple-mobile-web-app-title", content: "Coindisco" },
    {
      property: "og:site_name",
      content: "Coindisco",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:title",
      content: currentTitle,
    },
    {
      name: "twitter:title",
      content: currentTitle,
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:site",
      content: "@coindisco",
    },
    {
      name: "twitter:creator",
      content: "@coindisco",
    },
  ];

  if (ogImage) {
    meta.push({
      property: "og:image",
      content: `${PRODUCTION_DOMAIN}${ogImage}`,
    });
  }

  if (description) {
    meta.push(
      {
        name: "description",
        content: description,
      },
      {
        property: "og:description",
        content: description,
      },
      {
        name: "twitter:description",
        content: description,
      },
    );
  }

  if (isCanonical) {
    meta.push({
      tagName: "link",
      rel: "canonical",
      href: `${PRODUCTION_DOMAIN}${location.pathname}`,
    });
  }

  return meta;
};

const mergeMeta =
  <
    Loader extends LoaderFunction | unknown = unknown,
    ParentsLoaders extends Record<string, LoaderFunction | unknown> = Record<
      string,
      unknown
    >,
  >(
    metaCallback: MetaFunction<Loader, ParentsLoaders>,
  ): MetaFunction<Loader, ParentsLoaders> =>
  arg =>
    arg.matches.reduceRight((acc, match) => {
      for (const parentMeta of match.meta) {
        const index = acc.findIndex(
          meta =>
            ("name" in meta &&
              "name" in parentMeta &&
              meta.name === parentMeta.name) ||
            ("property" in meta &&
              "property" in parentMeta &&
              meta.property === parentMeta.property) ||
            ("title" in meta && "title" in parentMeta),
        );
        if (index == -1) {
          // Parent meta not found in acc, so add it
          acc.push(parentMeta);
        }
      }

      return acc;
    }, metaCallback(arg));

interface MetaCallbackArgs<
  Loader extends LoaderFunction | unknown = unknown,
  ParentsLoaders extends Record<string, LoaderFunction | unknown> = Record<
    string,
    unknown
  >,
> extends MetaArgs<Loader, ParentsLoaders> {
  allData: Record<string, unknown>;
}

const mergeArgs = <
  Loader extends LoaderFunction | unknown = unknown,
  ParentsLoaders extends Record<string, LoaderFunction | unknown> = Record<
    string,
    unknown
  >,
>(
  args: MetaArgs<Loader, ParentsLoaders>,
): MetaCallbackArgs<Loader, ParentsLoaders> => ({
  ...args,
  allData: args.matches.reduce(
    (acc, item) => Object.assign(acc, item.data),
    {},
  ),
});

interface MetaCallback<
  Loader extends LoaderFunction | unknown = unknown,
  ParentsLoaders extends Record<string, LoaderFunction | unknown> = Record<
    string,
    unknown
  >,
> {
  (args: MetaCallbackArgs<Loader, ParentsLoaders>): MetaConstructorParams;
}

export const createMeta = <
  Loader extends LoaderFunction | unknown = unknown,
  ParentsLoaders extends Record<string, LoaderFunction | unknown> = Record<
    string,
    unknown
  >,
>(
  callback: MetaCallback<Loader, ParentsLoaders>,
) =>
  mergeMeta<Loader, ParentsLoaders>((args: MetaArgs<Loader, ParentsLoaders>) =>
    getMeta(callback(mergeArgs(args)), args),
  );
